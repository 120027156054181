.ProseMirror {
  outline: none;

  table {
    // border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.editor {
  border: 1px solid #ccccd9;
  font-size: 16px;
  position: relative;
  box-shadow: 0 1px 4px rgb(0 0 0/8%);
  transition: box-shadow .2s ease;
  border-radius: 4px;

}

.toolbar {
  background-color: #f8f9fd;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #cdcde0;
  padding: 8px;

  button {
    padding:3px;
    margin: 5px;
    background-color: transparent;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    color: #68688a;
  
    img {
      width: 16px;
    }
  }
}

.editor-content{
  padding: 8px 16px;
}
